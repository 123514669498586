.about-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .about-title-art-container {
    display: flex;
    justify-content: center;
    margin: -2rem 0 1rem;

    .about-title-art {
      margin-top: 2rem;
      width: 30%;
      height: auto;

      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }

  .about-intro-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-right: 4.5rem;
    margin-top: 2rem;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-right: 0;
      text-align: center;
    }

    .avatar {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      margin-bottom: 1rem;
      transform: rotate(-30deg);

      @media (max-width: 768px) {
        width: 100px;
        height: 100px;
      }
    }

    .paragraph {
      font-size: 20px;
      margin-top: -0.5rem;
      max-width: 800px;
      line-height: 1.3;
      font-family: 'Helvetica', sans-serif;
    }
  }

  .about-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: -1rem;
    gap: 2rem;
    width: 800px;
    font-size: 20px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .about-hobbies-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      min-width: 1000px;
      width: 100%;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .avatar {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        transition: transform 0.3s ease;

        @media (max-width: 768px) {
          width: 80px;
          height: 80px;
        }
      }

      .avatar:hover {
        transform: scale(1.1);
      }

      p {
        margin-top: 0.5rem;
        font-family: 'Helvetica', sans-serif;
      }
    }
  }
}
