.App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  background-color: #f9f9f9;
  padding: 20px;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}
