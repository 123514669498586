.home-container {
    .home-page-art-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: -2rem auto 1rem auto;
      max-width: 800px;
  
      .home-page-art {
        margin-top: 1.5rem;
        width: 150%;
        height: auto;
      }
  
      .home-page-text-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 150%;
  
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          width: 100%;
        }
  
        .home-left {
          text-align: left;
  
          h1 {
            margin-top: 1rem;
            font-size: 64px;
          }
  
          h2 {
            margin-top: -2.5rem;
          }
  
          .resume-link {
            color: #1f1b1b;
            text-decoration: none;
            font-size: 28px;
            transition: color 0.3s;
  
            &:hover {
              color: #6a80c2;
            }
          }
        }
  
        .home-right {
          text-align: right;
          margin-top: 1.5rem;
  
          h2 {
            margin: 0 0 0.5rem 0;
  
            a {
              text-decoration: none;
              color: inherit;
              transition: color 0.3s;
  
              &:hover {
                color: #6a80c2;
              }
            }
  
            .arrow-icon {
              width: 20px;
              height: auto;
              margin-left: 0.2rem;
              vertical-align: middle;
              position: relative;
              bottom: 2px;
            }
          }
  
          @media (max-width: 768px) {
            text-align: left;
            margin-top: 0;
          }
        }
      }
    }
  }
  