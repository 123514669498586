.projects-title-art-container {
  display: flex;
  justify-content: center;
  margin: -2rem 0 1rem;

  .projects-title-art {
    width: 30%;
    height: auto;

    @media (max-width: 768px) {
      width: 80%;
    }
  }
}

.project-tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;

  .card-image {
    width: 100%;
    height: 200px;
    @media (max-width: 768px) {
      height: 130px;
    }
    object-fit: cover;
  }

  .p-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }
  }
}

.card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-top: 10px;
}

.project-details {
  grid-column: 1/2;
  overflow-y: auto;
  height: 80%;
  width: 80%;
  z-index: 20;
}

.modal-custom {
  width: 80vw;
  height: 60vh;
  max-width: 1000px;
  max-height: 800px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 100%;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
}

.modal-image {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.p-dialog-header,
.p-dialog-content {
  width: 100%;
}

.p-divider {
  width: 10%;
  background-color: '#333';
}

@media (max-width: 768px) {
  .project-tiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .project-tiles {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .projects-title-art-container {
    margin: -1rem 0 1rem;
  }

  .modal-custom {
    width: 90vw;
    height: 55vh;
  }

  .modal-content {
    padding: 0 10px 10px 10px;
  }
}
