.experience {
  padding: 20px;
  background-color: #f9f9f9;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .experience-tiles {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .experience-tile {
      display: flex;
      align-items: flex-start;
      padding: 20px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .experience-title-art-container {
    display: flex;
    justify-content: center;
    margin: -2rem 0 1rem;

    .experience-title-art {
      width: 30%;
      height: auto;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }

  .experience-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 50%;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 10px;
      width: 80px;
      height: 80px;
    }
  }

  .experience-info {
    flex: 1;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .experience-role {
    margin: 0;
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .experience-company {
    margin: 0.1rem 0 0.2rem;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .experience-time {
    margin: 0.2rem 0 0.5rem;
    color: #666;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .chips-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
  
    @media (min-width: 769px) {
      justify-content: flex-start;
    }
  }
  
  .language-chip {
    cursor: default;
    background-color: rgb(99, 134, 166);
    font-size: 12px; 
    color: white;
    @media (min-width: 769px) {
      font-size: 16px;
    }
  }
  

  .experience-details {
    margin-top: 1rem;
    gap: 15px;

    h1 {
      font-size: 28px;
      text-align: left;

      @media (max-width: 768px) {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    ul {
      font-size: 20px;
      line-height: 1.3;
      width: 100%;
      margin-left: -1rem;
      margin-top: -1rem;

      @media (max-width: 768px) {
        font-size: 1rem;
        margin-top: 0;
      }

      li {
        margin-bottom: 1rem;
      }
    }
  }

  h4 {
    margin-bottom: 5px;
  }

  .experience-languages-tools,
  .experience-tasks {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 8px;
    line-height: 140%;

    @media (max-width: 768px) {
      padding: 5px;
    }
  }

  .experience-languages-tools h4,
  .experience-tasks h4 {
    margin-top: 0;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .experience-languages-tools ul,
  .experience-tasks ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
  }
  .p-divider {
    width: 95%;
    height: 2px;
    background-color: #ccc;
    margin: 20px 0;
  
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }
  
    @media (min-width: 769px) {
      margin-left: 0;
      margin-right: 0;
      width: 95%;
    }
  }
}
