.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  color: #fff;
  padding: 20px 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .hamburger {
    cursor: pointer;
    display: none;

    span {
      font-size: 24px;
      color: black;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin: 0 10px;
    }

    a {
      font-family: Verdana, sans-serif;
      color: #1f1b1b;
      text-decoration: none;
      font-size: 24px;
      transition: color 0.3s;

      &:hover {
        color: #6a80c2;
      }
    }

    &.active {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #f9f9f9;
      flex-direction: column;
      z-index: 999;

      li {
        margin: 10px 0;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 10px;

    .hamburger {
      display: block;
    }

    ul {
      display: none;
      flex-direction: column;
      padding-left: 10px;
    }

    li {
      margin: 0 5px;
    }

    a {
      font-size: 18px;
    }
  }
}
